import { Offer, AdminListOffer } from './offer'
import { BsAdmin } from './bs'
import { Pl, PlAdmin } from './pl'
import { FavoriteUser, User, UserWithCompany } from './user'
import { Company, CompanyWithRegion } from './company'
import { Region } from './region'
import { Industry } from './industry'
import { Prefecture } from './prefecture'

interface SaleReason {
  id: number
  memo: string
  name: string
}

export interface ProjectSaleReason {
  id: number
  projectId: number | null
  saleReasonId: number | null
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  saleReasons: SaleReason
}
export interface ProjectTermsOfSale {
  id: number
  termsOfSaleId: number
}
export enum ProjectBuyer {
  Corporate = 1,
  Indivisual = 2,
  Either = 3,
}
export enum ProjectWhen {
  Now = 1,
  HalfAYear = 2,
  Year = 3,
  Depends = 4,
  ToBeDecided = 5,
}
export enum ProjectScheme {
  AllStocks = 1,
  SomeStocks = 2,
  Business = 3,
  Other = 8,
}
export enum ProjectStatus {
  Draft = 0,
  Wait = 1,
  Fixing = 2,
  Published = 3,
  Limited = 4,
  Completed = 5,
  Stop = 9,
}
export enum ProjectTakeOver {
  Keep = 1,
  Quarter = 2,
  HalfAYear = 3,
  Year = 4,
  MoreThanAYear = 5,
  Depends = 6,
  ToBeDiscussed = 7,
}

export enum ProjectTransactionTime {
  Now = 1,
  Year = 2,
  TwoYear = 3,
  Depends = 4,
}

export enum ProjectPickup {
  doNot = 0,
  do = 1,
}

export enum ProjectRelatedCompany {
  none = 0,
  canBe = 1,
}

export enum ProjectUseOption {
  Factory = 1,
  Sales = 2,
  Branch = 3,
  Other = 4,
}

export enum ProjectOwnerOption {
  Lease = 1,
  Company = 2,
  Person = 3,
}
export enum ProjectOrderOption {
  NewArrivalOrder = 'newArrivalOrder',
  RecommendedOrder = 'recommendedOrder',
  LowestAcquisitionPrice = 'lowestAcquisitionPrice',
  HighestAcquisitionPrice = 'highestAcquisitionPrice',
}

export interface Project {
  id: number
  userId: number
  companyId: number
  regionId: number | null
  industryId: number | null
  industryDetailId: number | null
  code: string
  title: string | null
  hasOgpImg: boolean | null
  feature: string | null
  capital: number | null
  scheme: ProjectScheme | null
  employeeNum: number | null
  status: ProjectStatus
  publishedAt: string | null
  establishedAt: string | null
  howMuch: number | null
  when: ProjectWhen | null
  buyer: ProjectBuyer | null
  takeOverPeriod: ProjectTakeOver | null
  reason: number | null
  saleReasonText: string | null
  ownedFacilityTxt: string | null
  termsOfSaleText: string | null
  businessTxt: string | null
  shareholderTxt: string | null
  supplierTxt: string | null
  vendorTxt: string | null
  license: string | null
  affiliateFlg: string | null
  pickUpFlg: number | null
  createdUserId: string | null
  createdAdminId: number | null
  updatedUserId: string | null
  updatedAdminId: number | null
  taxNoticeTxt: string | null
  noticeTxt: string | null
  financeMemo: string | null
  pv: number | null
  createdAt: string
  updatedAt: string
  projectsSaleReasons: ProjectSaleReason[]
  projectsTermsOfSales: ProjectTermsOfSale[]
  saleReasonParams?: number[]
  termsOfSaleParams?: number[]
  file?: File
  profit?: number | null
  publishRange?: number
  presetImageUrl?: string | null
}

export interface Blob {
  id: number
  key: string
  filename: string
  contentType: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: { [key: string]: any }
  byteSize: number
  checksum: string
  createdAt: Date | string
  updatedAt: Date | string
}
export interface Attachment {
  id: number
  name: string
  recordType: string
  recordId: number
  blobId: number
  activeStorageBlobs: Blob
  createdAt: Date
  updatedAt: Date
}

export interface ProjectWithCompany extends Project {
  company?: Company
}

export interface ProjectTermsOfSalesAdmin {
  id: number
  termsOfSaleId: number
  termsOfSales?: {
    id: number
    name: string
  }
}

export interface ProjectWithCompanyAdmin extends ProjectWithCompany {
  projectsSaleReasons: ProjectSaleReason[]
}

export interface ProjectWithMemoAdmin extends Project {
  name: string | null
  adminMemo: string | null
  staffComment: string | null
  projectsSaleReasons: ProjectSaleReason[]
}

export interface DownloadLog {
  ip: number
  siteUrl: string
  userAgent: string
  users: {
    id: number
    firstName: string
    lastName: string
    firstNameKana: string
    lastNameKana: string
    email: string
  }
  createdAt: string
  activeStorageAttachments: Attachment
}

export interface ProjectAdminOffice {
  id?: number
  projectId: number
  name: string
  category: number
  owner: number
  zipcode: number
  prefecture?: string
  prefectureId: number
  city: string
  address: string
  building: string
  memo?: string
  delete?: boolean
}

interface AuthorAdmin {
  name: string
}

export interface ProjectSaleConditions {
  scheme: ProjectScheme | null
  howMuch: number | null
  when: ProjectWhen | null
  buyer: ProjectBuyer | null
  takeOverPeriod: ProjectTakeOver | null
  reason: number | null
  saleReasonText: string | null
  termsOfSaleText: string | null
  projectsTermsOfSales: ProjectTermsOfSalesAdmin[]
}

export interface AdminProjectList extends Project {
  company: {
    name: string
    representativeName: string
    prefectures: {
      name: string
    }
  }
  user: User
  documents: Attachment[]
}

export type AdminProjectFile = ProjectFile & { projectFileAccessLogs?: ProjectFileAccessLog[] }

export interface ProjectAdminInfo {
  id: number
  title: string | null
  adminOnlySettings: {
    status: ProjectStatus
    pickUpFlg: number | null
    publishRange: number
  }
  authorAction: {
    createdUser: AuthorAdmin
    updatedUser: AuthorAdmin
    createdAdmin: AuthorAdmin
    updatedAdmin: AuthorAdmin
  }
  hasOgpImg: boolean
  matterInfo: {
    basicInfo: ProjectWithMemoAdmin
  }
  user: User
  company: CompanyWithRegion
  saleConditions: ProjectSaleConditions
  offers: AdminListOffer[]
  balanceSheet: BsAdmin
  profitAndLossStatement: PlAdmin[]
  remark: string | null
  howMuch: number | null
  downloadLogs: DownloadLog[]
  ndas: BuyerFile[]
  projectFiles: AdminProjectFile[]
  documents: Attachment[]
  profit: number | null
  presetImageUrl: string | null
  favoriteUsers: FavoriteUser[]
}

export interface ProjectAdminEdit extends Omit<ProjectAdminInfo, 'matterInfo' | 'saleConditions'> {
  id: number
  publicInfo: {
    title: string
    feature: string
    staffComment: string
  }
  matterInfo: {
    basicInfo: ProjectWithMemoAdmin
    offices: ProjectAdminOffice[]
  }
  saleConditions: Project
}

export interface ProjectMatching extends Project {
  region: Region
  industry: Industry
  amountOfSalesRange: number | null
  profit: number | null
  saleReasons: {
    id: number
    name: string
  }[]
  user: UserWithCompany
  company?: CompanyWithRegion
  totalFavorites?: number | null
  recommendProjects: ProjectMatching[]
  favoriteId?: number
  coordinator?: {
    id: number
    status: number
    isSeller: boolean
  }
  employeeRangeText: string
  offers?: Offer[]
}
interface CustomCompany extends Company {
  prefectures: Prefecture
}
export interface ProjectDetail extends Project {
  company: CustomCompany
  industry: Industry
  industryDetail?: {
    name: string
  }
  staffComment: string
  coordinator?: {
    id: number
    status: number
  }
}

export type AdminProjectDetail = ProjectDetail &
  ProjectMatching & {
    termsOfSales: ProjectTermsOfSale[]
  }

export interface ProjectFile {
  id: number
  projectId: number
  blobId: number
  shareRange: number
  projectFilesAllowedOffers: ProjectFileAllowedOffer[] | null
  blob: Blob
  createdAt: Date | string
  updatedAt: Date | string
}

interface ProjectFileAllowedOffer {
  id: number
  projectFileId: number
  offerId: number
  offer: {
    id: number
    projectId: number
    status: number
    messageTxt: string | null
    replyTxt: string | null
    buyerMemo: string | null
    sellerMemo: string | null
    ndaAcceptedAt: Date | string | null
    user: {
      lastName: string | null
    }
  }
}

export interface BuyerFile {
  id: number
  offerId: number
  blobId: number
  userId?: number
  shareRange: number
  buyerFileAllowedProjects: BuyerFileAllowedProject[] | null
  blob: Blob
  createdAt: Date
  updatedAt: Date
}

export interface BuyerFileAllowedProject {
  id: number
  buyerFileId: number
  projectId: number
  projects: {
    users: {
      lastName: string | null
    }
  }
  lastName: string | null
  createdAt: Date
}

export type Count = {
  notificationCounts?: number
  companyCounts?: number
  experiencedCounts?: number
  matchCounts?: number
}

export type NotificationFormData = {
  prefectureId?: number
  industryId?: number
  howMuch?: number
  publishRange?: number
}

export type ProjectFileAccessLog = {
  id: number
  isUpload: boolean
  buyerFileId: number | null
  ip: string
  projectFileId: number
  siteUrl: string
  userAgent: string
  createdAt: string
}

export interface StagnatingProject extends Project {
  user?: User
  pl?: Pl
  favoriteCount?: number
  offerCount?: number
}
