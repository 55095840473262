import { ProjectStatus } from 'types/project'
import { ObjectType } from 'types/object'

export const TITLE_LENGTH = 35
export const MAX_TEXT_LENGTH = 50000

export const PROJECT_STATUS_LABEL: ObjectType = {
  '0': '下書き',
  '1': '公開依頼中',
  '2': '修正依頼中',
  '3': '公開中',
  '4': '制限中',
  '5': '成約済み',
  '9': '公開停止中',
}

export const projectStatusList = {
  [ProjectStatus.Draft]: 'draft',
  [ProjectStatus.Fixing]: 'fixing',
  [ProjectStatus.Wait]: 'wait',
  [ProjectStatus.Published]: 'published',
  [ProjectStatus.Limited]: 'limited',
  [ProjectStatus.Completed]: 'completed',
  [ProjectStatus.Stop]: 'stop',
}

export const amountSalesRange: ObjectType = {
  0: '100万円未満',
  1000: '100万円〜500万円',
  5000: '500万円〜1,000万円',
  10000: '1,000万円〜5,000万円',
  50000: '5,000万円〜1億円',
  100000: '1億円〜2億5,000万円',
  250000: '2億5,000万円〜5億円',
  500000: '5億円〜10億円',
  1000000: '10億円〜25億円',
  2500000: '25億円〜50億円',
  5000000: '50億円〜100億円',
  10000000: '100億円〜250億円',
  25000000: '250億円〜500億円',
  50000000: '500億円以上',
}

export const PROFIT_RANGE: ObjectType = {
  '-100000': '-1000万円未満',
  '0': '-1000万円〜0円',
  '300': '0〜300万円',
  '3000': '300万円〜500万円',
  '5000': '500万円〜1,000万円',
  '10000': '1,000万円〜2,000万円',
  '20000': '2,000万円〜5,000万円',
  '50000': '5,000万円〜1億円',
  '100000': '1億円〜2億円',
  '200000': '2億円〜3億円',
  '300000': '3億円〜5億円',
  '500000': '5億円以上',
}

export const saleAmountInThousandYen: ObjectType = {
  '10000': '1,000万円',
  '50000': '5,000万円',
  '100000': '1億円',
}

export const saleAmountInThousandYenFeatureLabel: ObjectType = {
  '0': '下限',
  '10000': '1,000万円',
  '50000': '5,000万円',
  '100000': '1億円',
  '1000000000': '上限',
}

export const priceOfProjectInTenThousandYen: ObjectType = {
  '100': '100万円',
  '300': '300万円',
  '500': '500万円',
  '750': '750万円',
  '1000': '1,000万円',
  '2000': '2,000万円',
  '3000': '3,000万円',
  '5000': '5,000万円',
  '7500': '7,500万円',
  '10000': '1億円',
  '20000': '2億円',
  '50000': '5億円',
  '100000': '10億円',
}

export const featurePriceOfProjectInTenThousandYen: ObjectType = {
  '0': '下限',
  '100': '100万円',
  '300': '300万円',
  '500': '500万円',
  '750': '750万円',
  '1000': '1,000万円',
  '2000': '2,000万円',
  '3000': '3,000万円',
  '5000': '5,000万円',
  '7500': '7,500万円',
  '10000': '1億円',
  '20000': '2億円',
  '50000': '5億円',
  '100000': '10億円',
  '10000000': '上限',
}

export const numberOfEmployeesRange: ObjectType = {
  0: '社員なし',
  1: '5人以下',
  6: '10人以下',
  11: '20人以下',
  21: '30人以下',
  31: '50人以下',
  51: '100人以下',
  101: '200人以下',
  201: '201人以上',
}

export const SALE_AMOUNT_MIN = '0'
export const SALE_AMOUNT_MAX = '1000000000'

export const PRICE_PROJECT_MIN = '0'
export const PRICE_PROJECT_MAX = '10000000'

export const HISTORY_PROJECT_LIMIT = 10

export const COORDINATOR_STATUS = {
  INITIALIZED: 0,
  REVIEW: 1,
  PUBLISHED: 2,
  DECLINED: 3,
}

export const PROJECT_PUBLISH_RANGE = {
  ALL: 1,
  ONLY_MEMBER: 2,
} as const

export const PROJECT_PUBLISH_RANGE_LABEL: ObjectType = {
  1: 'すべてのサービス利用者（非会員含む）',
  2: 'M&Aナビ会員のみ',
}

export const EMPLOYEE_NUM_MIN = 0
